import axios from 'axios';
import {
  GET_USERS_URL,
  editUserUrl,
  getUserUrl,
  SEND_INVITE_URL,
  delUserUrl,
  createUserUrl,
  editUserTariff, ME_URL, editStatusUrl,
} from '../constants';
import {IUserAddProps, IUserEditProps, UserStatusType} from '../interfaces/user';

export function getUsers(page: number, perPage: number) {
  return axios.get(`${GET_USERS_URL}?page=${page}&per_page=${perPage}`);
}

export function getUsersByRole(page: number, perPage: number, roles: string) {
  return axios.get(`${GET_USERS_URL}?page=${page}&per_page=${perPage}&roles=${roles}`);
}

export function getUser(id: number | string) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(createUserUrl(), data);
}

export function editUser(id: number, data: IUserEditProps) {
  return axios.put(editUserUrl(id), data);
}

export function sendInvite(data: string) {
  return axios.get(`${SEND_INVITE_URL}?email=${data}`);
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}

export function createUserTariff(userId: number, tariffId: number, days?: number) {
  return axios.post(editUserTariff(userId, tariffId, days));
}

export function editStatus(id: number, status: UserStatusType) {
  return axios.post(editStatusUrl(id) +"?status="+ status);
}
