import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerSms } from '../../../crud/auth.crud';
import { getResponseMessage } from '../../../utils/utils';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { phoneFormat } from '../utils/phoneFormat';

export const useFetchRegPhone: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetchReg = useCallback(
    async (payload: { phone: string }) => {
      setLoading(true);
      registerSms(phoneFormat(payload.phone)!)
        .then((res) => {
          setSuccess(true);
          dispatch(authActions.checkPhoneSuccess());
        })
        .catch((e) => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    [dispatch]
  );

  return [fetchReg, loading, success];
};
