export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    icon: 'flaticon-logout',
  },

  products: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    submenu: [
      {
        title: 'Список категорий',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/new', 'categories/edit'],
      },
      {
        title: 'Дерево категорий',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
      {
        title: 'Скидки компании',
        page: 'products/list',
        translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
        activePages: [
          'products/create',
          // 'products/types/edit',
          'products/edit',
          // 'products/types/create',
        ],
      },
      {
        title: 'Лента избранных скидок',
        page: 'products/favorites',
        translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      },
      // {
      //   title: 'Торговые точки',
      //   page: 'stores/list',
      //   translate: 'MENU.CATALOG.STORES',
      // },
    ],
  },

  orders: {
    root: true,
    title: 'Заказы',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    submenu: [
      { translate: 'SUBMENU.ALLORDERS', title: 'SUBMENU.ALLORDERS', page: 'orders/list/full' },
      {
        translate: 'SUBMENU.ABANDONEDCHECKOUTS',
        title: 'UBMENU.ABANDONEDCHECKOUTS',
        page: 'orders/list/abandoned',
      },
    ],
  },

  companies: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    submenu: [
      { translate: 'SUBMENU.COMPANIES', title: 'SUBMENU.COMPANIES', page: 'companies/list' },
      {
        translate: 'MENU.CATALOG.STORES',
        title: 'MENU.CATALOG.STORES',
        page: 'stores/list',
        activePages: ['companies/new', 'companies/edit', 'companies/settings'],
      },
    ],
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    submenu: [
      { translate: 'SUBMENU.USER.ADD', title: 'SUBMENU.USER.ADD', page: 'users/new' },
      {
        translate: 'SUBMENU.CUSTOMERS',
        title: 'SUBMENU.CUSTOMERS',
        page: 'users/buyers',
        activePages: ['/users/edit/buyer'],
      },
      {
        translate: 'SUBMENU.TEAM',
        title: 'SUBMENU.TEAM',
        page: 'users/managers',
        activePages: ['users/edit/admin', 'users/edit/manager'],
      },
      {
        translate: 'SUBMENU.VENDORS',
        title: 'SUBMENU.VENDORS',
        page: 'users/vendors',
        activePages: ['users/edit/vendor'],
      },
      // {
      //   translate: 'SUBMENU.COMPANIES.ADD',
      //   title: 'SUBMENU.COMPANIES.ADD',
      //   page: 'companies/new',
      //   exact: true,
      // },
    ],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    submenu: [
      // {
      //   title: 'MENU.PRODUCTS.TYPES',
      //   translate: 'MENU.PRODUCTS.TYPES',
      //   page: 'products/types/list',
      //   activePages: ['products/types/edit', 'products/types/create'],
      // },
      {
        title: 'SUBMENU.PROFILE',
        translate: 'SUBMENU.PROFILE',
        page: 'settings/profile',
      },
      {
        title: 'SUBMENU.CITIES',
        translate: 'SUBMENU.CITIES',
        page: 'settings/cities/list',
      },
      {
        title: 'SUBMENU.TARIFFS',
        translate: 'SUBMENU.TARIFFS',
        page: 'settings/tariffs',
      },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.LEGAL_SHIPPING',
        translate: 'SUBMENU.LEGAL_SHIPPING',
        page: 'documents/shipping',
      },
      {
        title: 'SUBMENU.LEGAL_REFUND',
        translate: 'SUBMENU.LEGAL_REFUND',
        page: 'documents/refund',
      },
    ],
  },
};
