import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { recoveryPassword } from '../../../crud/auth.crud';
import { getResponseMessage } from '../../../utils/utils';
// import { actions as authActions } from '../../../store/ducks/auth.duck';

export const useFetchRecoveryPass: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetchRecovery = useCallback(
    async (payload: { email: string }) => {
      setLoading(true);
      recoveryPassword(payload.email)
        .then((res: any) => {
          setSuccess(true);
          // dispatch(authActions.recoveryPasswordSuccess(res.data));
          enqueueSnackbar(`${intl.formatMessage({ id: 'AUTH.FORGOT.EMAIL_SENT' })}`, {
            variant: 'success',
          });
          history.push('/auth');
        })
        .catch((e) => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    [dispatch]
  );

  return [fetchRecovery, loading, success];
};
