import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { register } from '../../../crud/auth.crud';
import { getResponseMessage } from '../../../utils/utils';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { IUserForRegister } from '../interfaces';
import { useHistory } from 'react-router-dom';

export const useFetchReg: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetchReg = useCallback(
    async (payload: IUserForRegister) => {
      setLoading(true);
      register(payload)
        .then((res) => {
          dispatch(authActions.regSuccess(res.data));
          setSuccess(true);
          history.push('/auth/email-sent/registration');
        })
        .catch((e) => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    [dispatch]
  );

  return [fetchReg, loading, success];
};
