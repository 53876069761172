/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { makeStyles, createStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import HomePage from '../pages/home/HomePage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import AuthPage from '../pages/auth/AuthPage';

import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';

import * as routerHelpers from './RouterHelpers';

import { IAppState } from '../store/rootDuck';
import axios from "axios";

const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      backgroundColor: '#0abb87 !important',
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  })
);

export const Routes = withRouter(({ history }) => {
  const classes = useStyles();
  const lastLocation = useLastLocation();

  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }: IAppState) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
    >
      {/* Create `LayoutContext` from current `history` and `menuConfig`. */}
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          {!isAuthorized ? (
            /* Render auth page when user at `/auth` and not authorized. */
            <AuthPage />
          ) : (
            /* Otherwise redirect to root page (`/`) */
            <Redirect from="/auth" to={'/settings/profile'} />
          )}

          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={LogoutPage} />

          {!isAuthorized ? (
            /* Redirect to `/auth` when user is not authorized */
            <Redirect to="/auth/login" />
          ) : (
            <Layout>
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          )}
        </Switch>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});
