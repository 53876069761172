import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { IAppState } from '../../store/rootDuck';

const CategoriesPage = lazy(() => import('./categories'));
const UsersPage = lazy(() => import('./users'));
const CompaniesPage = lazy(() => import('./companies'));
const UserDocPage = lazy(() => import('./userDocs'));
const SettingsPage = lazy(() => import('./settings'));
const ProductsPage = lazy(() => import('./products'));
const OrdersPage = lazy(() => import('./orders'));
const StorePage = lazy(() => import('./store'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation: any }> = ({
  userRoles,
  setMenuConfig,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles));

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /catalog. */
          <Redirect exact from="/" to="categories/list" />
        }
        <Route path="/settings" component={SettingsPage} />
        <Route path="/categories" component={CategoriesPage} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/documents" component={UserDocPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/companies" component={CompaniesPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/stores" component={StorePage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
  }),
  {
    setMenuConfig: builder.actions.setMenuConfig,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
